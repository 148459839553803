<template>
    <div class="kt-grid kt-grid--ver kt-grid--root">
        <div
            class="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v5"
            :style="{
                backgroundImage: `url(${backgroundImage})`
            }"
        >
            <div class="kt-error_container">
                <span class="kt-error_title">
                    <h1>OOPs !</h1>
                </span>
                <p class="kt-error_subtitle">
                    Unuthorized
                </p>
                <p class="kt-error_description">
                    Seems Like You are not the Owner of this Page.
                </p>
                <br>
                <span class="kt-error_description">
                <router-link :to="{name:'/dashboard'}" class="btn btn-primary">Back To Dashboard</router-link>
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-5.scss";
</style>

<script>
export default {
    name: "Error-5",
    mounted() {},
    computed: {
        backgroundImage() {
            return process.env.BASE_URL + "assets/media/error/bg5.jpg";
        }
    }
};
</script>
